// Enum for roles
export const Roles = {
  ADMIN: "ssciam-um-admin",
  PROFILE_VIEWER: "ssciam-um-profile",
  ACCESS_LOG_VIEWER: "ssciam-um-report-access-log",
  ADMIN_MT: "ssciam-um-admin-mt",
  PROFILE_VIEWER_MT: "ssciam-um-profile-mt",
  ACCESS_LOG_VIEWER_MT: "ssciam-um-report-access-log-mt",
  PROFILE: "ssciam-um-profile",
  PROFILE_MT: "ssciam-um-profile-mt",
  ROLE_ASSIGNMENT_VIEWER: "ssciam-rpt-userroleassignment",
};
