/* eslint-disable react/prop-types */

import { useContext, useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Paper,
  Grid,
  Tooltip,
  Checkbox,
  IconButton,
  Typography,
  Skeleton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import InfoIcon from "@mui/icons-material/Info";
import UserServiceProvider from "../../services/UserServiceProvider/UserServiceProvider";
import "./CreateUserComponent.css";
import { useNavigate, useParams } from "react-router";
import { Container, Stack } from "@mui/system";
import { useFormik } from "formik";
import { nameInfoTooltip, telephoneInfoTip, userNameInfoToppTip } from "../../helpers/TooltipsInfo/TooltipsInfo";
import { DataContext } from "../../contexts/DataContext";
import OrganizationTreeSturctureMeta from "../../helpers/OrganizationTreeSturctureMeta/OrganizationTreeSturctureMeta";
import "react-dual-listbox/lib/react-dual-listbox.css";
import DualListBox from "react-dual-listbox";
import "font-awesome/css/font-awesome.min.css";
import roleUtility from "../../helpers/RoleUtility/RoleUtility";
import { LeftDrawerList } from "../LeftDrawerList";
import * as Yup from "yup";
import {
  validationSchema_email,
  validationSchema_familyName,
  validationSchema_givenName,
  validationSchema_name,
  validationSchema_phone,
} from "../../helpers/ValidationSchema/ValidationSchema";
import { UseIdentityUser } from "../../helpers/UseIdentityUser";
import { RoleServiceProvider } from "../../services";
import { OrganizationList } from "../../hooks/OrganizationList";
import { UserManagerErrorNotification } from "../../helpers/UserManagerErrorNotification";
import AddEditUserSkeleton from "../AddEditUserSkeleton/AddEditUserSkeleton";
import DualListBoxSkeleton from "../DualListBoxSkeleton/DualListBoxSkeleton";
import InputUserName from "../../features/InputUserName/InputUserName";
import { useTenant } from "../../contexts/TenantService";
import { handleDualListBoxClick } from "../../helpers";

const CreateUserComponent = () => {
  const { tenantSvc } = useTenant();
  const isEntityPresent = tenantSvc?.featureToggle?.isEntityPresent;
  const userService = UserServiceProvider();
  const roleService = RoleServiceProvider();
  const { identityUser } = UseIdentityUser();
  const identityUserOrgId = identityUser?.organization;
  const OrgList = OrganizationList();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const { setSuccessUserName } = useContext(DataContext);
  const [selectedOrgId, setSelectedOrgId] = useState(identityUser?.organization);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const [userNameVal, setUserNameVal] = useState("");
  const [fname, setFName] = useState("");
  const [gname, setGName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [addAnother, setAddAnother] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [clickedRoleValue, setClickedRoleValue] = useState("");
  const [clickedEntityValue, setClickedEntityValue] = useState("");
  const [fields, setFields] = useState({
    familyName: "",
    givenName: "",
  });

  useEffect(() => {
    let errorTimeout;
    let successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => {
        setError(false);
        setGlobalErrorMsg("");
      }, 5000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  useEffect(() => {
    setSelectedOrgId(identityUser?.organization);
  }, [identityUser?.organization]);

  let org;
  let orgName = "";
  let userNameInfo = "";
  if (OrgList?.allOrgs?.data) {
    org = OrgList?.allOrgs?.data.find((o) => o.id === (selectedOrgId || identityUser?.organization));

    if (tenantSvc?.tenantId !== "psa" && org?.usernamePolicy) {
      userNameInfo = org.usernamePolicy?.userNamePatternInfo;
    }
    orgName = org?.name;
  }

  //const formContext = useFormContext();
  function grabUserName(userInput) {
    setUserNameVal(userInput);
  }

  const initialUserData = {
    name: userNameVal,
    givenName: gname,
    familyName: fname,
    email: email,
    phone: phone,
    roles: [],
    organization: selectedOrgId,
    active: false,
    description: "",
    status: "inactive",
  };

  const formik = useFormik({
    initialValues: initialUserData,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      givenName: validationSchema_givenName.givenName,
      familyName: validationSchema_familyName.familyName,
      phone: validationSchema_phone(tenantSvc?.featureToggle?.isPhoneRequired).phone(),
      name: validationSchema_name(org).name(),
      email: validationSchema_email.email,
    }),
    onSubmit: (values, { resetForm, setSubmitting }) => {
      let name;

      if (tenantSvc?.tenantId === "psa") {
        name = orgName === "worldline" ? userNameVal : `${tenantSvc?.tenantUsernamePrefix}_${userNameVal}`;
      } else {
        name = userNameVal;
      }

      values.name = name;
      values.roles = selectedRoles.concat(selectedEntities);
      userService
        .createUser("users", values)
        .then((response) => {
          if (addAnother) {
            const { organization, roles, ...resetValues } = initialUserData;
            console.log(organization, roles);
            setFName("");
            setGName("");
            setEmail("");
            setPhone("");
            setFields({
              familyName: "",
              givenName: "",
            });
            resetForm(resetValues);
            window.scrollTo(0, 0);
          }
          !addAnother && navigate(userURL);
          setSuccess(true);
          setSuccessUserName(response.data.name);
          setTimeout(() => {
            setSubmitting(false);
          }, 5000);
        })
        .catch((error) => {
          const errormessage = UserManagerErrorNotification(error?.response?.data);
          setError(true);
          setGlobalErrorMsg(errormessage);
          setTimeout(() => {
            setSubmitting(false);
          }, 5000);
        });
    },
  });
  useEffect(() => {
    setLoadingRoles(true);
    if (selectedOrgId || identityUser?.organization) {
      roleService
        .getRolesByOrganization("roles", selectedOrgId !== "undefined" ? selectedOrgId : identityUser?.organization)
        .then((response) => {
          setRoles(response);
          setLoadingRoles(false);
        });
    }
  }, [selectedOrgId]);

  const [roleOptions, setRoleOptions] = useState([]);
  const [entitiesOptions, setEntitiesOptions] = useState([]);
  let defaultRoleOptions = [];
  const clearAllSelectedRoles = () => {
    setSelectedEntities([]);
    setSelectedRoles([]);
  };

  useEffect(() => {
    if (roles?.data) {
      const rolesHavingNames = roles?.data?.filter((r) => Object.prototype.hasOwnProperty.call(r, "name"));
      const rolesEntityList = roleUtility.constructRolesAndEntitiesSet(rolesHavingNames, isEntityPresent);

      defaultRoleOptions = rolesHavingNames
        .filter((r) => r.defaultRole == true)
        .map((a) => {
          return a.id;
        });

      setRoleOptions(rolesEntityList?.rolesOnly);
      setEntitiesOptions(rolesEntityList?.entitiesOnly);
      setSelectedRoles(defaultRoleOptions);
    }
  }, [selectedOrgId, roles?.data]);

  useEffect(() => {
    const isRolesValid = selectedRoles.length > 1;
    const isEntitiesValid = selectedEntities.length > 0;
    const areGivenNameAndFamilyNameValid =
      formik.values.givenName.trim() !== "" || formik.values.familyName.trim() !== "";
    const isFormChanged =
      initialUserData.givenName !== formik.values.givenName ||
      initialUserData.familyName !== formik.values.familyName ||
      initialUserData.phone !== formik.values.phone ||
      initialUserData.email !== formik.values.email ||
      initialUserData.description !== formik.values.description;

    setSaveButtonEnabled(isRolesValid || isEntitiesValid || isFormChanged || areGivenNameAndFamilyNameValid);
  }, [selectedRoles, selectedEntities, formik.values]);

  const { tenant } = useParams();
  const theme = `${tenant}-theme`;
  const navigate = useNavigate();

  const userURL = `/ciam/v1/${tenant}/users`;
  if (OrgList?.isFetchingOrgs) {
    return <AddEditUserSkeleton />;
  }

  return (
    <>
      <div className={theme} id="create-user-top-wrapper">
        <div className="heading-bar">
          <Grid container spacing={1} className="createUserContainer">
            <Grid item xs={3} sm={4} md={4}>
              <Stack direction="row">
                <IconButton
                  edge="start"
                  onClick={() => setIsLeftDrawerOpen(true)}
                  sx={{ justifyContent: "flex-start", display: "flex" }}
                >
                  <Typography
                    component="img"
                    height="15px"
                    sx={{ position: "static" }}
                    src={process.env.PUBLIC_URL + "/static/images/drawer_menu_icon.png"}
                    className="expand-left-bar-icon"
                  />
                </IconButton>
                <Typography
                  variant="h4"
                  style={isLeftDrawerOpen ? { marginLeft: "100px" } : { marginLeft: "20px" }}
                  sx={{ fontSize: "22px", fontFamily: "Nunito", display: "flex", alignSelf: "center" }}
                >
                  Add User
                </Typography>
              </Stack>
            </Grid>
            <Grid md={3} sx={{ maxWidth: "20%" }} className="ErrorMessageGrid">
              {error && <div className="global-error-msg">{globalErrorMsg}</div>}
            </Grid>
          </Grid>
        </div>
        <LeftDrawerList isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen} />

        <Paper
          className="create-user-paper"
          elevation={3}
          style={isLeftDrawerOpen ? { marginLeft: "165px", width: "calc(100% - 230px)" } : { width: "90%" }}
        >
          <Container maxWidth={false}>
            <form defaultValue={identityUser?.organization}>
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 1, lg: 3 }} sx={{ mb: "5%" }}>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                  <FormControl component="fieldset">
                    <label className="input-label" aria-labelledby="organization">
                      Organization<span className="required-star">*</span>
                    </label>
                    {identityUser?.organization ? (
                      <OrganizationTreeSturctureMeta
                        name="organization"
                        onChange={formik.handleChange}
                        OrgList={OrgList}
                        baseOrganizationId={identityUserOrgId}
                        setOrgValue={setSelectedOrgId}
                        mode="create"
                        setSelectedRoles={clearAllSelectedRoles}
                        tenant={tenantSvc?.tenantId}
                        orgTitle="User creation is not allowed for this organization"
                      />
                    ) : (
                      <Skeleton variant="rectangular" width={200} height={40} />
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={2} sx={{ mb: "15px" }}>
                  <FormControl component="fieldset">
                    <label className="input-label">
                      Given name<span className="required-star">*</span>
                      <Tooltip title={nameInfoTooltip}>
                        <InfoIcon
                          sx={{ width: 14, height: 14, color: "#2D8282", verticalAlign: "middle", mb: 0.3, ml: "5px" }}
                        />
                      </Tooltip>
                    </label>
                    <TextField
                      name="givenName"
                      variant="outlined"
                      size="small"
                      placeholder="Enter"
                      error={formik.touched.givenName && formik.errors.givenName}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.setFieldValue("givenName", e.target.value);
                        setGName(e.target.value);
                        setFields((prevFields) => ({ ...prevFields, givenName: e.target.value }));
                      }}
                      value={gname}
                    />
                    {formik.touched.givenName && formik.errors.givenName ? (
                      <div className="error-msg">{formik.errors.givenName}</div>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                  <FormControl component="fieldset">
                    <label className="input-label">
                      Family name<span className="required-star">*</span>
                      <Tooltip title={nameInfoTooltip}>
                        <InfoIcon
                          sx={{ width: 14, height: 14, color: "#2D8282", verticalAlign: "middle", mb: 0.3, ml: "5px" }}
                        />
                      </Tooltip>
                    </label>
                    <TextField
                      name="familyName"
                      variant="outlined"
                      size="small"
                      placeholder="Enter"
                      error={formik.touched.familyName && formik.errors.familyName}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.setFieldValue("familyName", e.target.value);
                        setFName(e.target.value);
                        setFields((prevFields) => ({ ...prevFields, familyName: e.target.value }));
                      }}
                      value={fname}
                    />
                    {formik.touched.familyName && formik.errors.familyName ? (
                      <div className="error-msg">{formik.errors.familyName}</div>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                  <FormControl component="fieldset">
                    <label className="input-label">
                      User name<span className="required-star">*</span>
                      <Tooltip title={tenantSvc?.tenantId === "psa" ? userNameInfoToppTip : userNameInfo}>
                        <InfoIcon
                          sx={{ width: 14, height: 14, color: "#2D8282", verticalAlign: "middle", mb: 0.3, ml: "5px" }}
                        />
                      </Tooltip>
                    </label>
                    <InputUserName
                      org={org}
                      fields={fields}
                      grabit={grabUserName}
                      field={formik.getFieldProps("name")}
                      meta={formik.getFieldMeta("name")}
                      error={formik.touched.name && formik.errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                  <FormControl component="fieldset">
                    <label className="input-label">
                      Email<span className="required-star">*</span>
                    </label>
                    <TextField
                      name="email"
                      variant="outlined"
                      size="small"
                      placeholder="Enter"
                      onChange={(e) => {
                        formik.setFieldValue("email", e.target.value);
                        setEmail(e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={formik.touched.email && formik.errors.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error-msg">{formik.errors.email}</div>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={2}>
                  <FormControl component="fieldset">
                    <label className="input-label">
                      TELEPHONE{tenantSvc?.featureToggle?.isPhoneRequired && <span className="required-star">*</span>}
                      <Tooltip title={telephoneInfoTip}>
                        <InfoIcon
                          sx={{ width: 14, height: 14, color: "#2D8282", verticalAlign: "middle", mb: 0.3, ml: "5px" }}
                        />
                      </Tooltip>
                    </label>
                    <TextField
                      name="phone"
                      variant="outlined"
                      size="small"
                      placeholder="Enter"
                      onChange={(e) => {
                        formik.setFieldValue("phone", e.target.value);
                        setPhone(e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      error={formik.touched.phone && formik.errors.phone}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="error-msg">{formik.errors.phone}</div>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent={isEntityPresent ? "center" : "flex-start"}
                alignItems="start"
                sx={{ mt: "0px" }}
                className="grid-2"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={tenantSvc?.featureToggle?.isEntityPresent ? 6 : 12}
                  lg={tenantSvc?.featureToggle?.isEntityPresent ? 4 : 6}
                  data-testid="role-container"
                >
                  <label className="input-label">
                    SELECT ROLES
                    <Tooltip title="Multiple records can be selected and transferred">
                      <InfoIcon
                        sx={{ width: 14, height: 14, color: "#2D8282", verticalAlign: "middle", mb: 0.3, ml: "5px" }}
                      />
                    </Tooltip>
                  </label>
                  {!loadingRoles ? (
                    <>
                      <div
                        onClick={(e) =>
                          handleDualListBoxClick(e, e.target, roles?.data, setClickedRoleValue, setClickedEntityValue)
                        }
                      >
                        <DualListBox
                          name="roles"
                          canFilter
                          label="roles"
                          options={roleOptions}
                          disabled={selectedOrgId == "" ? true : false}
                          selected={selectedRoles}
                          onChange={(selected) => {
                            formik.setFieldValue("roles", selected);
                            setSelectedRoles(selected);
                          }}
                          style={{
                            backgroundColor: "#D2EDFF",
                            padding: "5px",
                          }}
                        />
                        {tenantSvc?.featureToggle?.showRolesDescription && (
                          <TextField
                            className="role-description"
                            fullWidth
                            placeholder="Description of a role"
                            value={clickedRoleValue}
                            minRows={1}
                            maxRows={3}
                            multiline
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <DualListBoxSkeleton />
                  )}
                </Grid>
                {isEntityPresent && (
                  <Grid item xs={12} sm={12} md={6} lg={4} data-testid="entity-container">
                    <label className="input-label">
                      SELECT ENTITIES
                      <Tooltip title="Multiple records can be selected and transferred">
                        <InfoIcon
                          sx={{ width: 14, height: 14, color: "#2D8282", verticalAlign: "middle", mb: 0.3, ml: "5px" }}
                        />
                      </Tooltip>
                    </label>
                    {!loadingRoles ? (
                      <div
                        onClick={(e) =>
                          handleDualListBoxClick(e, e.target, roles?.data, setClickedRoleValue, setClickedEntityValue)
                        }
                      >
                        <DualListBox
                          canFilter
                          id="entities"
                          label="entities"
                          options={entitiesOptions}
                          disabled={selectedOrgId == "" ? true : false}
                          selected={selectedEntities}
                          onChange={(selected) => {
                            setSelectedEntities(selected);
                          }}
                          style={{
                            backgroundColor: "#D2EDFF",
                            padding: "5px",
                          }}
                        />
                        {tenantSvc?.featureToggle?.showRolesDescription && (
                          <TextField
                            className="role-description"
                            fullWidth
                            placeholder="Description of an entity"
                            value={clickedEntityValue}
                            minRows={1}
                            maxRows={3}
                            multiline
                          />
                        )}
                      </div>
                    ) : (
                      <DualListBoxSkeleton />
                    )}
                  </Grid>
                )}
                <Grid item xs={12} sm={9} md={6} lg={2} sx={{ padding: "16px 30px" }} className="description-wrapper">
                  <label className="input-label">Description</label>
                  <TextField
                    id="decription"
                    multiline
                    rows={9}
                    placeholder="Enter"
                    variant="outlined"
                    sx={{ width: 1 }}
                    label=""
                    name="description"
                    maxLength="250"
                    maxHeight="20"
                    inputProps={{
                      maxLength: 500,
                    }}
                    value={formik.values.description}
                    helperText={`${formik.values.description.length}/500`}
                    onChange={formik.handleChange}
                    FormHelperTextProps={{ style: { margin: "1% 0 0 87%" } }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={6} lg={2} sx={{ padding: "16px 30px" }} className="Status-box">
                  <FormControl component="fieldset">
                    <label className="input-label">Status</label>
                    <RadioGroup aria-label="status" name="status" value="inactive" className="Statusbutton">
                      <FormControlLabel
                        value="active"
                        control={<Radio />}
                        disabled
                        label="Active"
                        className="activeStatus"
                      />
                      <FormControlLabel
                        value="inactive"
                        control={<Radio checked />}
                        className="InactiveStatus"
                        label="Inactive"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <hr />
              <Grid
                container
                spacing={1}
                sx={{ alignItems: "right", display: "flex", justifyContent: "flex-end", paddingRight: "40px" }}
              >
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  sx={{ display: "flex", justifyContent: "flex-end", maxHeight: "40px" }}
                  className="add-another-group"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setAddAnother(!addAnother)}
                        checked={addAnother}
                        sx={{
                          color: "#0099FF",
                          "&.Mui-checked": {
                            color: "#0099FF",
                          },
                        }}
                        className="add-another-check"
                      />
                    }
                    label="Add another"
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={4}
                  md={1}
                  lg={1}
                  className="toolbar-btn cancel-btn"
                  sx={{ justifyContent: "center" }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ borderRadius: "20px", padding: "6px 16px" }}
                    onClick={() => navigate(userURL)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={3} sm={2} md={1} lg={1} className="toolbar-btn" sx={{ justifyContent: "flex-end" }}>
                  {formik.isSubmitting ? (
                    <LoadingButton
                      className="loading-btn"
                      loading
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      sx={{ borderRadius: "20px", padding: "0 30px", maxWidth: "100px" }}
                    >
                      Save
                    </LoadingButton>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      className="save-btn"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      disabled={!isSaveButtonEnabled}
                    >
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          </Container>
        </Paper>
      </div>
    </>
  );
};

export default CreateUserComponent;
