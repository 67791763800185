const ErrorMessages = {
  ERR_NETWORK: "Unable to connect to the server. Please try again later.",
  BAD_REQUEST: "There was a problem with the request.",
  UNAUTHORIZED: "Error - Invalid token. You are not authorized to perform this action. Please log in and try again.",
  FORBIDDEN: "You don't have permission to access this resource.",
  NOT_FOUND: "The requested information could not be found. Please contact support.",
  INTERNAL_SERVER_ERROR: "There was a problem on our end. Please try again later or contact support.",
  ERR_UNKNOWN: "An unexpected error occurred. Please try again later or contact support.",
};

const HandleApiError = (error) => {
  if (error.response) {
    const { status, data } = error.response;
    const errorDescription = data.error_description || data;

    switch (status) {
      case 400:
        return `${errorDescription} ${ErrorMessages.BAD_REQUEST}`;
      case 401:
        return ErrorMessages.UNAUTHORIZED;
      case 403:
        return ErrorMessages.FORBIDDEN;
      case 404:
        return `${errorDescription} ${ErrorMessages.NOT_FOUND}`;
      case 500:
        return ErrorMessages.INTERNAL_SERVER_ERROR;
      case 503:
        return ErrorMessages.ERR_NETWORK;
      default:
        return ErrorMessages.ERR_UNKNOWN;
    }
  } else if (error.request) {
    return ErrorMessages.ERR_NETWORK;
  } else {
    return ErrorMessages.ERR_UNKNOWN;
  }
};

export default HandleApiError;
