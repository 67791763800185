import * as Yup from "yup";

const oneSpecialChar = new RegExp("[!#$'*+,-.?%@^_~=.]{1,}");
const anyForbidden = new RegExp("[^a-zA-Z\\d!#$'*+,-.?%@^_~=.]+");
const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4,11})(?: *x(\d+))?\s*$/;

const validationSchema_currentPassword = {
  currentPassword: Yup.string().required("Please enter a value"),
};
const validationSchema_confirmPassword = {
  confirmPassword: Yup.string().required("Please enter a value"),
};

const validationSchema_password = (passwordPolicy) => ({
  password: () => {
    let schema = Yup.string().required("Please enter a value");

    if (passwordPolicy) {
      schema = schema
        .min(passwordPolicy.minLength, `Password must be at least ${passwordPolicy.minLength} characters long`)
        .matches(passwordPolicy.digitPattern, "Password must contain at least 1 digit")
        .matches(passwordPolicy.lowerCasePattern, "Password must contain at least 1 lowercase letter")
        .matches(passwordPolicy.upperCasePattern, "Password must contain at least 1 uppercase letter")
        .matches(passwordPolicy.specialCharacterPattern, "Password must contain at least one special character")
        .matches(new RegExp(`^(?!.*${anyForbidden.source}).*$`), {
          message: "Password must not contain forbidden characters",
          excludeEmptyString: true,
        });
    } else {
      schema = schema
        .min(12, "Password must be at least 12 characters long")
        .matches(/^(?=.*\d)/, "Password must contain at least 1 digit")
        .matches(/^(?=.*[a-z])/, "Password must contain at least 1 lowercase letter")
        .matches(/^(?=.*[A-Z])/, "Password must contain at least 1 uppercase letter")
        .matches(oneSpecialChar, "Password must contain at least one special character")
        .matches(new RegExp(`^(?!.*${anyForbidden.source}).*$`), {
          message: "Password must not contain forbidden characters",
          excludeEmptyString: true,
        });
    }
    return schema;
  },
});

const validationSchema_givenName = {
  givenName: Yup.string()
    .required("Please enter a value")
    .min(2, "Must be 2 characters or more")
    .max(40, "Must be 50 characters or less")
    .matches(
      /^(?:[\p{Letter}\p{Mark}{äöüÄÖÜß}*]+[ \-'])*[\p{Letter}\p{Mark}{äöüÄÖÜß}*]+$/u,
      "Please enter a valid name"
    ),
};
const validationSchema_familyName = {
  familyName: Yup.string()
    .required("Please enter a value")
    .min(2, "Must be 2 characters or more")
    .max(40, "Must be 50 characters or less")
    .matches(
      /^(?:[\p{Letter}\p{Mark}{äöüÄÖÜß}*]+[ \-'])*[\p{Letter}\p{Mark}{äöüÄÖÜß}*]+$/u,
      "Please enter a valid name"
    ),
};
const validationSchema_phone = (isPhoneRequired) => ({
  phone: () => {
    let schema = Yup.string()
      .max(20, "Phone number must be at most 20 characters")
      .matches(phoneRegExp, "Please enter a valid phone number");

    if (isPhoneRequired) {
      schema = schema.required("Please enter a value");
    }
    return schema;
  },
});
const validationSchema_email = {
  email: Yup.string().email("Invalid email").required("Please enter a value"),
};

const validationSchema_name = (org) => ({
  name: () => {
    let schema = Yup.string().required("Please enter a value");

    if (org?.usernamePolicy) {
      schema = schema
        .min(org.usernamePolicy.minLength, `Must be ${org.usernamePolicy.minLength} characters or more`)
        .max(org.usernamePolicy.maxLength, `Must be ${org.usernamePolicy.maxLength} characters or less`)
        .matches(
          org.usernamePolicy.userNamePattern,
          `UserName does not match any of the patterns. ${org.usernamePolicy?.userNamePatternInfo}`
        );
    } else {
      schema = schema
        .min(8, "Must be 8 characters or more")
        .max(50, "Must be 50 characters or less")
        .matches(/^[a-z_0-9]*$/, "Contains illegal characters");
    }

    return schema;
  },
});

export {
  validationSchema_name,
  validationSchema_email,
  validationSchema_givenName,
  validationSchema_familyName,
  validationSchema_phone,
  validationSchema_currentPassword,
  validationSchema_password,
  validationSchema_confirmPassword,
};
