import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { useTenant } from "../../contexts/TenantService";

export const UserServiceProvider = () => {
  const { authService } = useAuth();
  const { tenantSvc } = useTenant();
  const apiUrl = tenantSvc?.apiUrl;
  const tenantIdentifier = tenantSvc?.tenantId;
  const token = authService?.getAccessToken();

  function getUsers(resource) {
    return axios.get(`${apiUrl}/${tenantIdentifier}/${resource}`, { headers: { Authorization: `bearer ${token}` } });
  }

  function createUser(resource, userData) {
    return axios.post(`${apiUrl}/${tenantIdentifier}/${resource}`, userData, {
      headers: { Authorization: `bearer ${token}` },
    });
  }

  function updateUser(resource, userData, userID) {
    return axios.put(`${apiUrl}/${tenantIdentifier}/${resource}/${userID}`, userData, {
      headers: { Authorization: `bearer ${token}` },
    });
  }

  function getUserByID(resource, userID) {
    return axios.get(`${apiUrl}/${tenantIdentifier}/${resource}/${userID}`, {
      headers: { Authorization: `bearer ${token}` },
    });
  }

  function getUserProfile(resource) {
    return axios.get(`${apiUrl}/${tenantIdentifier}/users/${resource}`, {
      headers: { Authorization: `bearer ${token}` },
    });
  }

  function UpdateUserProfile(resource, profileObject) {
    return axios.put(`${apiUrl}/${tenantIdentifier}/${resource}/`, profileObject, {
      headers: { Authorization: `bearer ${token}` },
    });
  }

  return { getUsers, createUser, updateUser, getUserByID, getUserProfile, UpdateUserProfile };
};

export default UserServiceProvider;
