import BaseReport from "../BaseReport/BaseReport";
import RoleAssignmentTable from "../RoleAssignmentTable/RoleAssignmentTable";
function RoleAssignmentReport() {
  const getRoleAssignmentDetails = async (roleService) => roleService.getRoleAssignmentDetails();

  return (
    <BaseReport
      title="Role Assignment"
      getDataService={getRoleAssignmentDetails}
      tableComponent={RoleAssignmentTable}
      className="role-assignment-table-wrapper"
    />
  );
}

export default RoleAssignmentReport;
