import { Route, BrowserRouter, Routes } from "react-router-dom";
import { UserListPage } from "../../pages/UserListPage";
import { CreateUserPage } from "../../pages/CreateUserPage";
import { EditUserPage } from "../../pages/EditUserPage";
import DashBoardPage from "../../pages/DashBoardPage/DashBoardPage";
import ProfilePage from "../../pages/ProfilePage/ProfilePage";
import { CredMgmtSetPasswordPage } from "../../pages/CredMgmtSetPasswordPage";
import CredMgmtForgotPasswordPage from "../../pages/CredMgmtForgotPasswordPage/CredMgmtForgotPasswordPage";
import { Roles } from "./Roles";
import RoleAssignmentReportPage from "../../pages/RoleAssignmentReportPage/RoleAssignmentReportPage";
import { Footer } from "../Footer";
function SecApp() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/ciam/v1/:tenant/profile"
            element={
              <ProfilePage
                allowedRoles={[
                  Roles.ADMIN,
                  Roles.PROFILE_VIEWER,
                  Roles.PROFILE_VIEWER_MT,
                  Roles.ADMIN_MT,
                  Roles.ACCESS_LOG_VIEWER_MT,
                ]}
              />
            }
          />
          <Route
            path="/ciam/v1/:tenant/users/create"
            element={<CreateUserPage allowedRoles={[Roles.ADMIN, Roles.ADMIN_MT]} />}
          />
          <Route
            path="/ciam/v1/:tenant/users/edit/:id"
            element={<EditUserPage allowedRoles={[Roles.ADMIN, Roles.ADMIN_MT]} />}
          />
          <Route
            path="/ciam/v1/:tenant"
            element={
              <DashBoardPage
                allowedRoles={[Roles.ADMIN, Roles.PROFILE_VIEWER, Roles.ADMIN_MT, Roles.PROFILE_VIEWER_MT]}
              />
            }
          />
          <Route
            path="/ciam/v1/:tenant/users"
            element={<UserListPage allowedRoles={[Roles.ADMIN, Roles.ADMIN_MT]} />}
          />
          <Route
            path="/ciam/v1/:tenant/reports/role-assignment"
            element={<RoleAssignmentReportPage allowedRoles={[Roles.ROLE_ASSIGNMENT_VIEWER]} />}
          />
          <Route path="/ciam/v1/:tenant/accounts/activate" element={<CredMgmtSetPasswordPage />} />
          <Route path="/ciam/v1/:tenant/accounts/change-password" element={<CredMgmtSetPasswordPage />} />
          <Route path="/ciam/v1/:tenant/accounts/forgot-password" element={<CredMgmtForgotPasswordPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default SecApp;
