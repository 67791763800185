// RoleServiceProvider.js
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { useTenant } from "../../contexts/TenantService";

export const RoleServiceProvider = () => {
  const { authService } = useAuth();
  const { tenantSvc } = useTenant();
  const tenantIdentifier = tenantSvc?.tenantId;
  const token = authService?.getAccessToken();
  function getRoles(resource) {
    return axios.get(`${tenantSvc?.apiUrl}/${tenantIdentifier}/${resource}`, {
      headers: { Authorization: `bearer ${token}` },
    });
  }

  function getRolesByOrganization(resource, selectedID) {
    if (tenantSvc) {
      return axios.get(`${tenantSvc?.apiUrl}/${tenantIdentifier}/${resource}?organization=` + selectedID, {
        headers: { Authorization: `bearer ${token}` },
      });
    }
  }

  function getRoleByID(resource, roleID) {
    const apiUrl = tenantSvc?.apiUrl;
    return axios.get(`${apiUrl}/${tenantIdentifier}/${resource}/${roleID}`, {
      headers: { Authorization: `bearer ${token}` },
    });
  }
  function getRoleAssignmentDetails() {
    const roleUrl = tenantSvc?.roleAssignmentUrl;
    if (!roleUrl) {
      return undefined;
    }
    return axios
      .get(`${roleUrl}`, {
        headers: { Authorization: `bearer ${token}` },
      })
      .catch((error) => {
        throw error;
      });
  }

  return { getRoles, getRoleByID, getRolesByOrganization, getRoleAssignmentDetails };
};

export default RoleServiceProvider;
