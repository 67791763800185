import { useEffect, useState } from "react";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { LeftDrawerList } from "../LeftDrawerList";
import "./BaseReport.css";
import { HandleapiError, TransformJsonDataUtil } from "../../helpers";
import { RoleServiceProvider } from "../../services";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

function BaseReport({ title, getDataService, tableComponent: TableComponent }) {
  const { tenant } = useParams();
  const theme = `${tenant}-theme`;
  const roleService = RoleServiceProvider();
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const [dataLoading, setdataLoading] = useState(true);
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState({ before: 0, after: 0 });

  useEffect(() => {
    if (dataLoading) {
      getDataService(roleService)
        .then((response) => {
          setRawData(response?.data);
          setData(TransformJsonDataUtil(response?.data));
          setdataLoading(false);
        })
        .catch((error) => {
          setdataLoading(false);
          const errorMessage = HandleapiError(error);
          setError(errorMessage);
        });
    }
  }, [dataLoading, getDataService, roleService]);

  return (
    <div className={theme} id="base-report-wrapper" data-testid="base-report-wrapper">
      <div className="heading-bar">
        <Grid container spacing={1} className="report-container">
          <Grid item xs={12} md={4}>
            <Stack direction="row" className="report-sub-heading-wrapper">
              <IconButton
                edge="start"
                onClick={() => setIsLeftDrawerOpen(true)}
                sx={{ justifyContent: "flex-start", display: "flex" }}
              >
                <Typography
                  component="img"
                  height="15px"
                  sx={{ position: "static" }}
                  src={process.env.PUBLIC_URL + "/static/images/drawer_menu_icon.png"}
                  className="expand-left-bar-icon"
                  alt="drawer menu icon"
                />
              </IconButton>
              <Typography
                variant="h4"
                style={isLeftDrawerOpen ? { marginLeft: "140px" } : { marginLeft: "20px" }}
                sx={{ fontSize: "22px", fontFamily: "Nunito", display: "flex", alignSelf: "center" }}
              >
                {title}
                {!dataLoading && ` - ${totalData.after} ${totalData.after === 1 ? "Record" : "Records"} `}
              </Typography>
            </Stack>
          </Grid>
          <Grid md={3} sx={{ maxWidth: "20%" }} className="ErrorMessageGrid">
            {error && <div className="global-error-msg">{error}</div>}
          </Grid>
        </Grid>
      </div>
      <LeftDrawerList isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen} />
      <Grid
        container
        spacing={1}
        className="report-table-wrapper"
        data-testid="report-table"
        sx={isLeftDrawerOpen ? { width: "88vw", marginLeft: "12vw" } : { width: "98vw" }}
      >
        <Grid item xs={12}>
          <TableComponent data={data} dataLoading={dataLoading} setTotalData={setTotalData} rawData={rawData} />
        </Grid>
      </Grid>
    </div>
  );
}

BaseReport.propTypes = {
  title: PropTypes.string.isRequired,
  getDataService: PropTypes.func.isRequired,
  tableComponent: PropTypes.elementType.isRequired,
};

export default BaseReport;
