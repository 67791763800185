function UserManagerErrorNotification(error, message) {
  let errorMsg = "";

  if (error?.error_description && error?.error_validations) {
    errorMsg = `Error: ${error?.error_validations.map((n) => n.message)}`;
  } else if (error?.error_description) {
    errorMsg = `Error: ${error?.error_description}`;
  } else {
    errorMsg = "Unknown error";
  }
  const lastErrorMessage = message ? message : "Please try again";
  const finalErrorMessage = errorMsg + ". " + lastErrorMessage;
  return finalErrorMessage;
}

export default UserManagerErrorNotification;
