import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { UserServiceProvider } from "../../services/UserServiceProvider";

const UseIdentityUser = () => {
  const userService = UserServiceProvider();
  const { authService } = useAuth();
  const [identityUser, setIdentityUser] = useState();
  const [userProfile, setUserProfile] = useState();
  const [shouldFetchUser, setShouldFetchUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await authService.getProfile();
      setUserProfile(profile);
      setShouldFetchUser(true);
    };

    fetchProfile();
  }, [authService]);

  useEffect(() => {
    if (shouldFetchUser && userProfile) {
      userService.getUserProfile("profile").then((identity) => {
        setShouldFetchUser(false);
        setIdentityUser(identity?.data);
        setLoading(false);
      });
    }
  }, [shouldFetchUser]);

  return { identityUser, shouldFetchUser, loading };
};

export default UseIdentityUser;
